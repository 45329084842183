import { nanoid } from 'nanoid';
import { omit, trimEnd } from 'lodash';
import { getCurrentInstance } from 'vue';
const STORAGE_KEY = '__navigator__';
export const PID_KEY = '_pid';
function getKey(route) {
    return trimEnd(route.path, '/') + '?' + route.query[PID_KEY];
}
const navigator = {
    routes: [],
    record(route, isReplace) {
        const key = getKey(route);
        if (isReplace) {
            this.routes.splice(this.routes.length - 1, 1, key);
        }
        else {
            const index = this.routes.lastIndexOf(key);
            // forward
            if (index === -1) {
                this.routes.push(key);
                // back
            }
            else if (index !== this.routes.length - 1) {
                this.routes.pop();
            }
        }
        this.save();
    },
    isBack(to, from) {
        const toIndex = this.routes.lastIndexOf(getKey(to));
        const fromIndex = this.routes.lastIndexOf(getKey(from));
        if (toIndex === -1)
            return false;
        return toIndex < fromIndex;
    },
    save() {
        window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.routes));
    },
    restore() {
        try {
            const rawRoutes = window.sessionStorage.getItem(STORAGE_KEY);
            this.routes = rawRoutes ? JSON.parse(rawRoutes) : [];
        }
        catch { }
    },
};
function installNavigator(Vue, router) {
    navigator.restore();
    let isReplace = false;
    const originalReplace = router.replace;
    // @ts-expect-error It's not an error
    router.replace = (...args) => {
        isReplace = true;
        return originalReplace.apply(router, args);
    };
    router.beforeEach((to, from, next) => {
        if (to.query[PID_KEY]) {
            next();
        }
        else {
            next({
                ...omit(to, 'name'),
                query: {
                    ...to.query,
                    [PID_KEY]: nanoid(),
                },
                replace: isReplace || !from.query[PID_KEY],
            });
        }
    });
    router.afterEach((to) => {
        navigator.record(to, isReplace);
        isReplace = false;
    });
    Vue.prototype.$navigator = {
        // @exposed-api
        isBack(to, from) {
            return navigator.isBack(to, from);
        },
    };
}
export const useNavigator = () => {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error('[navigator]: Missing current instance. useNavigator() must be called inside <script setup> or setup().');
    }
    return instance.proxy.$root.$navigator;
};
export default {
    install(Vue, options) {
        const { router } = options ?? {};
        if (!router) {
            throw new Error('navigator need options.router');
        }
        installNavigator(Vue, router);
    },
};
