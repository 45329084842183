/* vant-demand-import */
import installComponents from "!../../../plugins/vant-demand-import/installComponents.js"
import VanForm from 'vant/es/form'
import 'vant/es/form/style/less'


import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=ea0dfe50&"
import script from "./Form.vue?vue&type=script&lang=ts&setup=true&"
export * from "./Form.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
installComponents(component, {VanForm})
