/* eslint-disable no-var */
/**
 * 安装组件工具函数，该模块会被sfc引用
 * 实现方式参考 vuetify-loader
 * @see https://github.com/vuetifyjs/vuetify-loader/blob/master/lib/runtime/installComponents.js
 */
module.exports = function installComponents(component, components) {
  var options =
    typeof component.exports === 'function' ? component.exports.extendOptions : component.options;

  if (typeof component.exports === 'function') {
    options.components = component.exports.options.components;
  }

  options.components = options.components || {};

  for (var key in components) {
    if (options.components[key] == null) {
      options.components[components[key].name] = components[key];
    }
  }
};
