import { clearAllPoll } from '@/shared/poll';
export default function clearPollGuard(router) {
    /**
     * 切换路由后清理掉所有轮询
     */
    router.beforeEach((to, from, next) => {
        clearAllPoll();
        next();
    });
}
