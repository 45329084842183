import cheesesBackgroundImage from '@/assets/img/cheeses/bg.png';
export default [
    /**
     * 芝士权益
     */
    {
        path: '/partner/cheeses',
        meta: {
            customNavBar: true,
            excludeLogin: true,
            excludeTracking: true,
            title: '悦卡会员',
            background: `#F3F5F7 url(${cheesesBackgroundImage}) no-repeat 0 0 / 100% auto`,
        },
        component: () => import('@/views/cheeses/index.vue'),
    },
    /**
     * 芝士权益排队
     */
    {
        path: '/partner/cheeses/queue',
        meta: {
            title: '排队中',
            excludeLogin: true,
            background: '#fff',
        },
        component: () => import('@/views/cheeses/queue.vue'),
    },
];
