var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"x-nav-bar"},[(_vm.placeholder && _vm.fixed)?_c('div',{staticClass:"x-nav-bar__placeholder",style:({ height: `${_setup.height}px` })}):_vm._e(),_c('div',{ref:"navBar",class:[
      'x-nav-bar-inner',
      {
        'x-nav-bar--fixed': _vm.fixed,
        'van-hairline--bottom': _vm.border,
        'x-nav-bar--safe-area-inset-top': _vm.safeAreaInsetTop,
      },
    ],style:({ zIndex: _vm.zIndex, background: _vm.background })},[_vm._t("background"),_c('div',{staticClass:"x-nav-bar__content"},[(_setup.hasLeft)?_c('div',{staticClass:"x-nav-bar__left",on:{"click":function($event){return _setup.emit('click-left', $event)}}},[_vm._t("left",function(){return [(_vm.leftArrow)?_c('van-icon',{staticClass:"x-nav-bar__arrow",style:({ color: _vm.color }),attrs:{"name":"arrow-left"}}):_vm._e(),(_vm.leftText)?_c('span',{staticClass:"x-nav-bar__text",style:({ color: _vm.color })},[_vm._v(" "+_vm._s(_vm.leftText)+" ")]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"x-nav-bar__title van-ellipsis",style:({ color: _vm.color })},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2),(_setup.hasRight)?_c('div',{staticClass:"x-nav-bar__right",on:{"click":function($event){return _setup.emit('click-right', $event)}}},[_vm._t("right",function(){return [_c('span',{staticClass:"x-nav-bar__text"},[_vm._v(_vm._s(_vm.rightText))])]})],2):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }