var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('van-popup',_vm._g(_vm._b({class:[
    'x-popup',
    {
      'x-popup--safe-area-inset-top': _vm.safeAreaInsetTop,
      'x-popup--safe-area-inset-bottom': _vm.safeAreaInsetBottom,
    },
  ],attrs:{"value":_vm.value,"close-icon-position":_vm.closeIconPosition},on:{"input":_setup.onInput}},'van-popup',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"x-popup-inner"},[(_setup.hasNavBar || _vm.$scopedSlots.header)?_c('div',{ref:"header",staticClass:"x-popup__header"},[(_setup.hasNavBar)?_c('x-nav-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})]},proxy:true},(_vm.$scopedSlots.action)?{key:"right",fn:function(){return [_vm._t("action")]},proxy:true}:null],null,true)}):_vm._e(),_vm._t("header")],2):_vm._e(),_c('div',{staticClass:"x-popup__content",style:({ height: _setup.contentHeight })},[_vm._t("default")],2),(_vm.$scopedSlots.footer)?_c('div',{ref:"footer",staticClass:"x-popup__footer"},[_vm._t("footer")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }