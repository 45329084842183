/* vant-demand-import */
import installComponents from "!../../../plugins/vant-demand-import/installComponents.js"
import VanPopup from 'vant/es/popup'
import 'vant/es/popup/style/less'
import '/Users/evan/work/benefits-mobile/vant-extra/style-override/popup.css'


import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=0da63830&"
import script from "./Popup.vue?vue&type=script&lang=ts&setup=true&"
export * from "./Popup.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./Popup.vue?vue&type=style&index=0&id=0da63830&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
installComponents(component, {VanPopup})
