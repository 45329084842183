/**
 * 格式化时间
 * @param {Number} value 单位：秒
 */
export default function formatTime(value) {
    if (typeof value !== 'number')
        return value;
    let str = '';
    const days = Math.floor(value / 864e2);
    if (days > 0) {
        str += `${days}天`;
    }
    const hours = Math.floor(value / 36e2) % 24;
    if (hours > 0) {
        str += `${hours}小时`;
    }
    const minutes = Math.floor(value / 60) % 60;
    if (minutes > 0) {
        str += `${minutes}分钟`;
    }
    const seconds = value % 60;
    if (seconds > 0) {
        str += `${seconds}秒`;
    }
    return str;
}
