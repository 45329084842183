import axios from 'axios';
import { Toast } from 'vant';
import FullscreenLoading from '@/components/loading/fullscreen';
import { BizCode } from '@/shared/constants';
import { isPlainObject } from 'lodash';
import getABTestKey from './abtest';
import PUBLIC_KEY from '@/publicKey';
import { useUTMStore } from '@/stores/modules/utm';
import { useUserStore } from '@/stores/modules/user';
const BIZ_ERROR = 'BizError';
const instance = axios.create({
    baseURL: `${process.env.VUE_APP_PREFIX}api/v1`,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
});
/**
 * 控制台统一输出异常
 * @param {Error} error
 */
function outputError(error) {
    const { response } = error;
    const config = response.config;
    // eslint-disable-next-line no-console
    console.error('%s\n url: %s\n method: %s\n params: %s\n response: %s', error.message, config.url, config.method, JSON.stringify(config.data || config.params || null), JSON.stringify(response.data));
}
/**
 * 跳转登录页
 */
function gotoLogin() {
    const pathname = window.location.pathname.replace(process.env.VUE_APP_PREFIX, '/');
    // 若当前是登录页则重新刷新
    // 理论上这个情况不存在
    if (pathname === '/login') {
        // eslint-disable-next-line no-underscore-dangle
        window.__g_app__.forceReload();
        return;
    }
    const { source } = useUTMStore();
    // eslint-disable-next-line no-underscore-dangle
    window.__g_router__.replace({
        path: '/login',
        query: {
            utm_source: source,
            redirect: '/',
        },
    });
}
/**
 * 用户信息设置
 */
instance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-underscore-dangle
    const loading = config.__interceptorOptions__?.loading ?? true;
    if (loading) {
        FullscreenLoading.show();
    }
    if (config.headers) {
        const { token, isLogin } = useUserStore();
        config.headers['X-ABTest-Key'] = getABTestKey();
        if (isLogin && token != null) {
            config.headers['X-Token'] = token;
        }
    }
    return config;
});
/**
 * 参数加密
 */
instance.interceptors.request.use((config) => {
    const security = config.__interceptorOptions__?.security ?? false;
    /**
     * 以下场景设置默认参数
     * 1. 启用了加密
     * 2. 参数没传
     * 3. 请求为POST
     *
     * 之所以这么处理是因为Spring中的 @RequestBody 注解默认为必传，
     * 如果改为 @RequestBody(required = false) 则影响范围较大，因此在拦截器层面对于此场景填充默认参数
     */
    if (security && config.data == null && config.method?.toUpperCase() === 'POST') {
        config.data = {};
    }
    // 仅生产环境或设置了VUE_APP_SECURITY为true才进行加密
    if ((process.env.VUE_APP_RUNTIME_ENV === 'prod' || process.env.VUE_APP_SECURITY === 'true') &&
        security) {
        try {
            const { aesKey, data } = CECryptojs.RSAWithAES.encrypt(PUBLIC_KEY, JSON.stringify(config.data ?? {}), 'AES-CBC');
            config.__aesKey__ = aesKey;
            config.data = data;
        }
        catch { }
    }
    return config;
});
/**
 * 返回数据解密
 */
instance.interceptors.response.use((response) => {
    const security = response.config.__interceptorOptions__?.security ?? false;
    // 仅生产环境或设置了VUE_APP_SECURITY为true才进行解密
    if ((process.env.VUE_APP_RUNTIME_ENV === 'prod' || process.env.VUE_APP_SECURITY === 'true') &&
        security) {
        try {
            const aesKey = response.config.__aesKey__ ?? '';
            const { ciphertext, nonce } = response.data ?? {};
            response.data = JSON.parse(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            CECryptojs.AES.decrypt(aesKey, nonce, ciphertext, 'AES-CBC'));
        }
        catch { }
    }
    return response;
});
/**
 * 处理业务状态码
 */
instance.interceptors.response.use((response) => {
    FullscreenLoading.hide();
    const result = response.data;
    if (result.status === BizCode.SUCCESS) {
        return result.data;
    }
    const error = new Error(`Request failed with biz status code ${result.status}${result.msg ? `, ${result.msg}` : ''}`);
    error.name = BIZ_ERROR;
    error.response = response;
    const { toast = true, redirectToLogin = true } = response.config?.__interceptorOptions__ ?? {};
    if (process.env.NODE_ENV === 'development') {
        outputError(error);
    }
    // 未登录或登录失效
    if (result.status === BizCode.UNAUTHORIZED) {
        if (redirectToLogin) {
            gotoLogin();
        }
    }
    else if (toast) {
        Toast.fail(result.msg || ' 未知错误');
    }
    return Promise.reject(error);
}, (error) => {
    FullscreenLoading.hide();
    if (!isPlainObject(error.response)) {
        Toast.fail('遇到了一个未知的错误\n您的网络可能存在问题');
        return Promise.reject(error);
    }
    const { status, statusText, config } = error.response;
    const { toast = true, redirectToLogin = true } = config?.__interceptorOptions__ ?? {};
    if (status === 401) {
        if (redirectToLogin) {
            gotoLogin();
        }
    }
    else if (toast) {
        if (status === 403) {
            Toast.fail('您没有权限访问');
        }
        else {
            Toast.fail(statusText || ' 未知错误');
        }
    }
    if (process.env.NODE_ENV === 'development') {
        outputError(error);
    }
    return Promise.reject(error);
});
export default instance;
