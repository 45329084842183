import { defineComponent } from 'vue';
import { PullRefresh as VanPullRefresh } from 'vant';
import withInstall from '@/shared/withInstall';
const PullRefresh = defineComponent({
    name: 'XPullRefresh',
    extends: VanPullRefresh,
    props: {
        disabled: Boolean,
        successText: String,
        pullingText: String,
        loosingText: String,
        loadingText: String,
        pullDistance: [Number, String],
        value: {
            type: Boolean,
            required: true,
        },
        successDuration: {
            type: [Number, String],
            default: 500,
        },
        animationDuration: {
            type: [Number, String],
            default: 300,
        },
        headHeight: {
            type: [Number, String],
            default: 50,
        },
    },
    emits: ['refresh'],
    methods: {
        ease(distance) {
            const pullDistance = +(this.pullDistance || this.headHeight || 50);
            distance **= 0.85;
            if (distance > pullDistance) {
                if (distance < pullDistance * 2) {
                    distance = pullDistance + (distance - pullDistance) / 2;
                }
                else {
                    distance = pullDistance * 1.5 + (distance - pullDistance * 2) / 4;
                }
            }
            return Math.round(distance);
        },
    },
});
export const XPullRefresh = withInstall(PullRefresh);
export default XPullRefresh;
