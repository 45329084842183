import Vue from 'vue';
import { detectEnv } from '@/shared/detect';
import SATracking from '@/shared/saTracking';
import { useUTMStore } from '@/stores/modules/utm';
/**
 * 设置神策埋点全局属性
 */
export function setupRegisterPageGuard(router, pinia) {
    const utmStore = useUTMStore(pinia);
    router.beforeEach((to, from, next) => {
        let platform = 'web';
        if (detectEnv.yxh) {
            if (detectEnv.android) {
                platform = 'android';
            }
            if (detectEnv.ios) {
                platform = 'ios';
            }
        }
        // 先清除下页面中可能注册的全局埋点属性
        SATracking.clearPageRegister();
        // 设置埋点全局参数
        SATracking.registerPage({
            utm_source: to.query.utm_source ?? utmStore.source,
            platform_type: platform,
            bu: 'zhishi',
            referrer: window.__vue_router_referrer__,
        });
        next();
    });
}
/**
 * 神策默认PV埋点
 * 不使用is_track_single_page是因为统计到的title是变更前的
 */
export function trackPageViewGuard(router) {
    router.afterEach((to) => {
        Vue.nextTick(() => {
            if (!to.meta?.excludeTracking) {
                SATracking.trackPageView();
            }
        });
    });
}
