/* vant-demand-import */
import installComponents from "!../../../plugins/vant-demand-import/installComponents.js"
import VanEmpty from 'vant/es/empty'
import 'vant/es/empty/style/less'


import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=2708a179&"
import script from "./Empty.vue?vue&type=script&lang=ts&setup=true&"
export * from "./Empty.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./Empty.vue?vue&type=style&index=0&id=2708a179&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
installComponents(component, {VanEmpty})
