import { render, staticRenderFns } from "./SvgIcon.vue?vue&type=template&id=5db7cf8e&"
import script from "./SvgIcon.vue?vue&type=script&lang=ts&setup=true&"
export * from "./SvgIcon.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./SvgIcon.vue?vue&type=style&index=0&id=5db7cf8e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports