/* eslint-disable @typescript-eslint/ban-types */
import { isFunction, isPlainObject } from 'lodash';
import { isRef } from 'vue';
const hasOwn = (obj, key) => Object.hasOwnProperty.call(obj, key);
const isNative = (Ctor) => {
    return typeof Ctor === 'function' && /native code/.test(Ctor.toString());
};
const hasSymbol = typeof Symbol !== 'undefined' &&
    isNative(Symbol) &&
    typeof Reflect !== 'undefined' &&
    isNative(Reflect.ownKeys);
/**
 * Helper that recursively merges two data objects together.
 */
function mergeData(from, to) {
    if (!from)
        return to;
    if (!to)
        return from;
    let key;
    let toVal;
    let fromVal;
    const keys = hasSymbol ? Reflect.ownKeys(from) : Object.keys(from);
    for (let i = 0; i < keys.length; i++) {
        key = keys[i];
        // in case the object is already observed...
        if (key === '__ob__')
            continue;
        toVal = to[key];
        fromVal = from[key];
        if (!hasOwn(to, key)) {
            to[key] = fromVal;
        }
        else if (toVal !== fromVal &&
            isPlainObject(toVal) &&
            !isRef(toVal) &&
            isPlainObject(fromVal) &&
            !isRef(fromVal)) {
            mergeData(fromVal, toVal);
        }
    }
    return to;
}
export default function mergeSetup(
// eslint-disable-next-line @typescript-eslint/ban-types
parent, 
// eslint-disable-next-line @typescript-eslint/ban-types
child) {
    return function mergeSetupFn(props, context) {
        return mergeData(isFunction(parent) ? parent(props, context) ?? {} : undefined, isFunction(child) ? child(props, context) ?? {} : undefined);
    };
}
