import SATracking from '@/shared/saTracking';
function getInputs(el) {
    const nodeName = el.nodeName.toLowerCase();
    if (nodeName === 'input' || nodeName === 'textarea') {
        return [el];
    }
    const nodeList = el.querySelectorAll('input, textarea');
    return Array.from(nodeList);
}
function handler(event) {
    const el = event.target;
    SATracking.trackInputChange({
        custom_element_type: el.nodeName.toLowerCase(),
        custom_element_name: el.name,
        custom_element_class_name: el.getAttribute('class'),
        custom_element_selector: SATracking.getSelector(el),
        desc: el.getAttribute('placeholder'),
    });
}
export default {
    bind(el) {
        const nodeList = getInputs(el);
        if (nodeList) {
            for (let i = 0; i < nodeList.length; i++) {
                nodeList[i].addEventListener('change', handler);
            }
        }
    },
    unbind(el) {
        const nodeList = getInputs(el);
        if (nodeList) {
            for (let i = 0; i < nodeList.length; i++) {
                nodeList[i].removeEventListener('change', handler);
            }
        }
    },
};
