import { defineStore } from 'pinia';
export const useUTMStore = defineStore('utm', {
    state: () => ({
        source: 'yrxd_web',
        medium: '',
        campaign: '',
        term: '',
        content: '',
        name: '', // 渠道来源对应的中文名称
    }),
    actions: {
        update(payload) {
            this.source = payload.source ?? 'yrxd_web';
            this.medium = payload.medium ?? this.medium;
            this.campaign = payload.campaign ?? this.campaign;
            this.content = payload.content ?? this.content;
            this.term = payload.term ?? this.term;
            this.name = payload.name ?? '';
        },
    },
});
