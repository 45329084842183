import clearPollGuard from './clearPoll';
import transferQueriesGuard from './transferQueries';
import loginGuard from './login';
import referrerGuard from './referrer';
import bodyStyleGuard from './bodyStyle';
import titleGuard from './title';
import { setupRegisterPageGuard, trackPageViewGuard } from './tracker';
export function installGuards(router, pinia) {
    /**
     * 顺序有要求，请勿随意调整
     */
    [
        clearPollGuard,
        transferQueriesGuard,
        loginGuard,
        referrerGuard,
        setupRegisterPageGuard,
        bodyStyleGuard,
        titleGuard,
        trackPageViewGuard, // afterEach
    ].forEach((guard) => guard(router, pinia));
}
