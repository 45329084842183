import { YXH_DOMAINS } from '@/shared/constants';
import BigNumber from 'bignumber.js';
import importScript from '@/shared/importScript';
import simpleTween from '@/shared/simpleTween';
/**
 * 去除字符串中全部空格
 */
export function trimAll(str) {
    return str.replace(/\s+/g, '');
}
/**
 * 字符串转数值
 */
export function string2number(str) {
    if (typeof str === 'number')
        return str;
    const value = Number.parseFloat(str);
    if (Number.isNaN(value)) {
        return 0;
    }
    return value;
}
/**
 * 判断是否为Promise对象
 */
export function isPromise(value) {
    return (value != null &&
        (typeof value === 'object' || typeof value === 'function') &&
        typeof value.then === 'function' &&
        typeof value.catch === 'function');
}
/**
 * 判断路径是否为宜享花域名
 * @param url
 */
export function isYxhDomain(url) {
    const [, domain] = url.match(/^(?:https?:)?(?:\/\/)?([\w.-]+)\S*$/) ?? [];
    if (domain) {
        return YXH_DOMAINS.includes(domain);
    }
    return false;
}
/**
 * 获取当前域名
 */
export function getCurrentDomain() {
    const { origin } = window.location;
    return `${origin}${process.env.VUE_APP_PREFIX}`;
}
/**
 * 抖音小程序环境已就绪
 */
export function weappReady() {
    return new Promise((resolve, reject) => {
        if (typeof wx !== 'undefined' && wx.miniProgram) {
            resolve();
            return;
        }
        importScript(`${process.env.BASE_URL}sdk/jweixin-1.6.0.js`, {
            retry: true,
        })
            .then(() => {
            resolve();
        })
            .catch(reject);
    });
}
/**
 * 抖音小程序环境已就绪
 */
export function dyappReady() {
    return new Promise((resolve, reject) => {
        if (typeof tt !== 'undefined' && tt.miniProgram) {
            resolve();
            return;
        }
        importScript(`${process.env.BASE_URL}sdk/douyin-jssdk-1.2.1.js`, {
            retry: true,
        })
            .then(() => {
            resolve();
        })
            .catch(reject);
    });
}
/**
 * 获取指定元素的scrollTop
 */
export function getScrollTop(el) {
    const top = 'scrollTop' in el ? el.scrollTop : el.scrollY;
    return Math.max(0, top);
}
/**
 * 获取指定元素到页面顶部的距离
 */
export function getOffsetTop(el) {
    if (!el) {
        return 0;
    }
    const win = el.ownerDocument.defaultView;
    return Math.round(el.getBoundingClientRect().top + (win?.pageYOffset ?? 0));
}
// 获取当前滚动的位置
export function getCurrentScrollTop() {
    return Math.round(document.documentElement.scrollTop);
}
// 滚动到指定位置
export function scrollTo(end, onComplete, duration = 400) {
    const scrollTop = getCurrentScrollTop();
    simpleTween({
        start: scrollTop,
        end,
        duration,
        onUpdate(v) {
            window.scrollTo(0, v);
        },
        onComplete,
    });
}
/**
 * vw/vh单位转换像素
 */
export function unit2pixel(value) {
    if (typeof value === 'number') {
        return value;
    }
    if (value.indexOf('vw') !== -1) {
        return new BigNumber(+value.replace(/vw/g, ''))
            .times(window.innerWidth)
            .div(100)
            .integerValue(BigNumber.ROUND_FLOOR)
            .toNumber();
    }
    if (value.indexOf('vh') !== -1) {
        return new BigNumber(+value.replace(/vh/g, ''))
            .times(window.innerHeight)
            .div(100)
            .integerValue(BigNumber.ROUND_FLOOR)
            .toNumber();
    }
    return parseFloat(value);
}
/**
 * 设置iframe跳转方式为顶部窗口
 */
export function setJumpToTopWindow(iframe) {
    if (iframe == null || iframe.contentWindow == null) {
        return;
    }
    const doc = iframe.contentWindow.document;
    const a = doc.querySelectorAll('a');
    if (a) {
        for (let i = 0, item; (item = a[i++]);) {
            if (item.href !== null && item.href !== '') {
                item.target = '_top';
            }
        }
    }
}
/**
 * 文件数据类型
 */
export var FileDataTypes;
(function (FileDataTypes) {
    FileDataTypes[FileDataTypes["ARRAY_BUFFER"] = 1] = "ARRAY_BUFFER";
    FileDataTypes[FileDataTypes["BINARY_STRING"] = 2] = "BINARY_STRING";
    FileDataTypes[FileDataTypes["DATA_URL"] = 3] = "DATA_URL";
    FileDataTypes[FileDataTypes["TEXT"] = 4] = "TEXT";
})(FileDataTypes || (FileDataTypes = {}));
/**
 * 文件转换成指定数据类型
 */
export function file2dataType(file, dataType = FileDataTypes.DATA_URL) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            if (data) {
                resolve(data);
            }
            else {
                reject(new Error('文件解析失败，请重新选择文件'));
            }
        };
        reader.onerror = () => {
            reject(new Error('文件解析失败，请重新选择文件'));
        };
        switch (dataType) {
            case FileDataTypes.ARRAY_BUFFER:
                reader.readAsArrayBuffer(file);
                break;
            case FileDataTypes.BINARY_STRING:
                reader.readAsBinaryString(file);
                break;
            case FileDataTypes.DATA_URL:
                reader.readAsDataURL(file);
                break;
            case FileDataTypes.TEXT:
                reader.readAsText(file);
                break;
        }
    });
}
/**
 * 生成一个随机数字
 */
export function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
 * 判断是否存在navbar, 自定义navbar不在范围内
 */
export function existsNavBar(route) {
    // 页面地址中设置了hasNavBar为0
    if (route.query.hasNavBar === '0') {
        return false;
    }
    // 判断当前路由是否嵌套在包含了nav-bar的布局中
    const nestedNavBarLayout = route.matched?.find((item) => item.meta.hasNavBarLayout);
    if (!nestedNavBarLayout) {
        return false;
    }
    return true;
}
/**
 * 判断元素是否隐藏
 */
export function isElementHidden(el) {
    const style = window.getComputedStyle(el);
    const hidden = style.display === 'none';
    // offsetParent returns null in the following situations:
    // 1. The element or its parent element has the display property set to none.
    // 2. The element has the position property set to fixed
    const parentHidden = el.offsetParent === null && style.position !== 'fixed';
    return hidden || parentHidden;
}
/**
 * 获取滚动元素父级
 */
const overflowScrollRegex = /scroll|auto|overlay/i;
export function getScrollParent(el, root = window) {
    let node = el;
    while (node &&
        node.tagName !== 'HTML' &&
        node.tagName !== 'BODY' &&
        node.nodeType === 1 &&
        node !== root) {
        const { overflowY } = window.getComputedStyle(node);
        if (overflowScrollRegex.test(overflowY)) {
            return node;
        }
        node = node.parentNode;
    }
    return root;
}
/**
 * 添加px单位
 */
export function addUnit(value) {
    if (typeof value === 'number' && Number.isFinite(value)) {
        return `${value}px`;
    }
    return value;
}
/**
 * 获取页面标题
 */
export function getTitle(route) {
    if (route.meta?.title) {
        return route.meta.title;
    }
    return '权益礼包';
}
/**
 * 判断是否为有效数组
 */
export function isValidArray(arr) {
    return Array.isArray(arr) && arr.length > 0;
}
/**
 * 判断是否为有效时间
 */
export function isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
}
