import BigNumber from 'bignumber.js';
/**
 * 格式化折扣
 */
export default function formatDiscount(value) {
    let newValue = value;
    if (newValue == null || newValue === '') {
        newValue = 0;
    }
    return new BigNumber(newValue).div(10).toString();
}
