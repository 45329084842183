/* vant-demand-import */
import installComponents from "!../../../plugins/vant-demand-import/installComponents.js"
import VanIcon from 'vant/es/icon'
import 'vant/es/icon/style/less'


import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=38056a67&"
import script from "./NavBar.vue?vue&type=script&lang=ts&setup=true&"
export * from "./NavBar.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=38056a67&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
installComponents(component, {VanIcon})
