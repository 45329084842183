/**
 * 需要在URL上传递的querystring参数
 */
export const transferQueries = ['hasNavBar', 'brand', 'eac', 'hasAppInfo'];
export const TRANSFERRED_KEY = '_tk';
export default function transferQueriesGuard(router) {
    /**
     * querystring参数透传，通常用于全局参数
     * 注意非特殊情况尽量使用Store
     */
    router.beforeEach((to, from, next) => {
        if (to.query[TRANSFERRED_KEY] === '1') {
            next();
            return;
        }
        const transferredQueries = Object.keys(from.query).reduce((acc, key) => {
            if (transferQueries.includes(key)) {
                acc[key] = from.query[key];
            }
            return acc;
        }, {});
        if (Object.keys(transferredQueries).length > 0) {
            next({
                ...to,
                query: {
                    ...to.query,
                    ...transferredQueries,
                    [TRANSFERRED_KEY]: '1',
                },
            });
            return;
        }
        next();
    });
}
