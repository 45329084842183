import sensors from 'sa-sdk-javascript';
function defaultProps() {
    return {
        $title: document.title,
        $url: window.location.href,
        $url_path: window.location.pathname,
        $referrer: window.__vue_router_referrer__,
    };
}
/**
 * 创建自定义埋点方法
 * @param name 事件名
 * @param properties 传递的属性
 * @returns Promise<void>
 */
function createTracker(name, properties) {
    return new Promise((resolve) => {
        sensors.track(name, {
            ...defaultProps(),
            ...properties,
        }, () => {
            resolve();
        });
    });
}
let initialized = false;
/**
 * 初始化神策SDK
 */
function init(options) {
    if (initialized)
        return;
    sensors.init({
        server_url: 'https://sadata.yixin.com/sa?project=' + options.projectName,
        show_log: options.showLog ?? false,
        callback_timeout: 500,
        app_js_bridge: true,
        heatmap: {
            clickmap: 'default',
            scroll_notice_map: 'default',
            collect_element: function (element_target) {
                if (element_target.getAttribute('disabled') != null ||
                    element_target.getAttribute('data-sa-disabled') != null) {
                    return false;
                }
                return true;
            },
            custom_property: function (element_target) {
                const properties = {};
                const desc = element_target.getAttribute('data-sa-desc');
                if (desc != null) {
                    properties.desc = desc;
                }
                const describe = element_target.getAttribute('data-sa-describe');
                if (describe != null) {
                    properties.describe = describe;
                }
                const placeholder = element_target.getAttribute('placeholder');
                if (element_target.tagName.toLowerCase() === 'input' &&
                    placeholder != null &&
                    properties.desc == null) {
                    properties.desc = placeholder;
                }
                return properties;
            },
        },
    });
    initialized = true;
}
/**
 * 神策登录用户ID
 */
function login(id) {
    return new Promise((resolve) => {
        if (!initialized) {
            resolve();
            return;
        }
        sensors.login(String(id), () => {
            resolve();
        });
    });
}
/**
 * 神策退出登录
 */
function logout() {
    if (!initialized)
        return;
    sensors.logout();
}
/**
 * 注册全局参数
 */
function registerPage(properties) {
    if (!initialized)
        return;
    // registerPage的属性会执行合并操作，不会覆盖已设置的属性
    sensors.registerPage(properties);
}
/**
 * 清除全局参数
 */
function clearPageRegister() {
    if (!initialized)
        return;
    // @ts-ignore
    sensors.clearPageRegister(true);
}
/**
 * 弹窗事件埋点
 */
function trackPopup(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('popup', properties);
}
/**
 * PV事件埋点
 */
function trackPageView(properties) {
    if (!initialized)
        return;
    // 暂时去掉PageLeave插件，因为目前暂时没有用，但产生了大量事件导致费用上涨
    // sensors.use('PageLeave', { custom_props: properties });
    sensors.quick('autoTrackSinglePage', properties);
}
/**
 * 子页面事件埋点
 */
function trackSubPageView(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('child_page', properties);
}
/**
 * 接口结果埋点
 */
function trackInterfaceResults(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('interface_results', properties);
}
/**
 * 登录事件埋点
 */
function trackLogin(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('registe', {
        ...properties,
        is_login: true, // 固定传值，数据团队要求的
    });
}
/**
 * 输入内容变更埋点
 */
function trackInputChange(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('change', properties);
}
/**
 * 点击埋点
 */
function trackClick(target, properties) {
    if (!initialized)
        return Promise.resolve();
    return new Promise((resolve) => {
        sensors.quick('trackHeatMap', target, properties, () => {
            resolve();
        });
    });
}
/**
 * 滑动区域埋点
 */
function trackSwipe(properties) {
    if (!initialized)
        return Promise.resolve();
    return createTracker('swipe', properties);
}
/**
 * 根据DOM节点获取选择器路径
 */
function getSelector(el) {
    // @ts-ignore
    return sensors.heatmap.getDomSelector(el);
}
/**
 * 序列化JSON数据
 */
function serialize(data) {
    try {
        return JSON.stringify(data);
    }
    catch {
        return '';
    }
}
const SATracking = {
    init,
    login,
    logout,
    registerPage,
    clearPageRegister,
    trackPopup,
    trackClick,
    trackPageView,
    trackSubPageView,
    trackInterfaceResults,
    trackLogin,
    trackInputChange,
    trackSwipe,
    getSelector,
    serialize,
};
export function SATrackingPlugin(Vue) {
    Vue.prototype.$tracking = SATracking;
}
export default SATracking;
