import 'abort-controller/polyfill';
import 'intersection-observer';
import Vue from 'vue';
import Navigator from '@/plugins/navigator';
import ChunkLoadError from '@/plugins/chunk-load-error';
import desensitize from '@/shared/filters/desensitize';
import cent2yuan from '@/shared/filters/cent2yuan';
import formatAmount from '@/shared/filters/formatAmount';
import formatDiscount from '@/shared/filters/formatDiscount';
import App from './App.vue';
import { createRouter } from './router';
import { installGuards } from './guards';
import trackChange from '@/shared/directives/trackChange';
import SATracking, { SATrackingPlugin } from '@/shared/saTracking';
import setSafeAreaInsetProperty from '@/shared/setSafeAreaInsetProperty';
import VueClipboard from 'vue-clipboard2';
// 引入公共组件
import FontIcon from '@/x-components/font-icon';
import SvgIcon from '@/x-components/svg-icon';
import Empty from '@/x-components/empty';
import Form from '@/x-components/form';
import Popup from '@/x-components/popup';
import Badge from '@/x-components/badge';
import Sticky from '@/x-components/sticky';
import NavBar from '@/x-components/nav-bar';
import CountUp from '@/x-components/count-up';
import Placeholder from '@/x-components/placeholder';
import PullRefresh from '@/x-components/pull-refresh';
import TranslucentNavBar from '@/components/translucent-nav-bar.vue';
import CheckboxWithTracking from '@/components/checkbox-with-tracking.vue';
import NavBarWithTracking from '@/components/nav-bar-with-tracking.vue';
import { Toast, Notify, Dialog, Lazyload } from 'vant';
import formatTime from '@/shared/filters/formatTime';
import createPinia from '@/stores';
import mergeSetup from './mergeSetup';
import '@/styles/vars.css';
import '@/styles/reset.css';
import '@/styles/base.css';
// 基于CSS变量的安全区处理
setSafeAreaInsetProperty();
// 临时方案，Vue 2.7/3.x 中setup在mixin中不会被合并，此方案目前主要用于mixin向setup过渡时的兼容性保证。
// 当所有mixin改为setup实现后，此处应该被删除
Vue.config.optionMergeStrategies.setup = mergeSetup;
Vue.use(VueClipboard);
// vant组件
Vue.use(Toast);
Vue.use(Notify);
Vue.use(Dialog);
Vue.use(Lazyload);
// 通用组件
Vue.use(FontIcon);
Vue.use(SvgIcon);
Vue.use(Empty);
Vue.use(Form);
Vue.use(Popup);
Vue.use(Badge);
Vue.use(Sticky);
Vue.use(NavBar);
Vue.use(CountUp);
Vue.use(Placeholder);
Vue.use(PullRefresh);
// 业务通用组件
Vue.component('CheckboxWithTracking', CheckboxWithTracking);
Vue.component('NavBarWithTracking', NavBarWithTracking);
Vue.component('TranslucentNavBar', TranslucentNavBar);
const router = createRouter();
const pinia = createPinia();
/**
 * 神策埋点初始化
 */
if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_SADATA_DEBUG === 'true') {
    let projectName = 'yxhzs';
    let showLog = false;
    if (process.env.VUE_APP_RUNTIME_ENV !== 'prod') {
        projectName = 'yxhcs';
        showLog = true;
    }
    SATracking.init({
        projectName,
        showLog,
    });
}
Vue.use(Navigator, { router });
Vue.use(ChunkLoadError, { router });
Vue.use(SATrackingPlugin);
/**
 * 设置所有toast的默认参数
 */
Toast.setDefaultOptions({ duration: 3000 });
/**
 * 设置所有dialog的默认参数
 */
Dialog.setDefaultOptions({
    closeOnPopstate: false,
});
Vue.filter('desensitize', desensitize);
Vue.filter('cent2yuan', cent2yuan);
Vue.filter('formatAmount', formatAmount);
Vue.filter('formatDiscount', formatDiscount);
Vue.filter('formatTime', formatTime);
Vue.directive('track-change', trackChange);
/**
 * 暴露全局router提供给axios使用
 */
window.__g_router__ = router;
/**
 * 安装路由导航守卫/拦截器
 */
installGuards(router, pinia);
/**
 * 暴露全局app
 */
window.__g_app__ = new Vue({
    router,
    pinia,
    data() {
        return {
            isRouterAlive: true,
        };
    },
    render(h) {
        if (this.isRouterAlive) {
            return h(App);
        }
        return h();
    },
    methods: {
        /**
         * 强制刷新当前页面
         */
        forceReload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
}).$mount('#app');
