import { defineStore } from 'pinia';
import hash from '@/shared/hash';
import * as userService from '@/services/user';
import { isUndefined } from 'lodash';
import SATracking from '@/shared/saTracking';
const makeIdentifier = (v) => (v == null ? null : hash(v));
export const useUserStore = defineStore('user', {
    state: () => ({
        token: null,
        identifier: null,
    }),
    getters: {
        isLogin(state) {
            return state.token != null && state.token !== '';
        },
        isNotLogin() {
            return !this.isLogin;
        },
    },
    actions: {
        update(payload) {
            this.token = isUndefined(payload.token) ? this.token : payload.token;
            this.identifier = isUndefined(payload.phone)
                ? this.identifier
                : makeIdentifier(payload.phone);
        },
        /**
         * 登录
         */
        async login(payload) {
            try {
                const data = await userService.login({
                    phone: payload.phone,
                    verifyCode: payload.captcha,
                });
                const identifier = makeIdentifier(payload.phone);
                if (this.identifier !== identifier) {
                    await this.logout();
                }
                this.update({
                    token: data.token,
                    phone: payload.phone,
                });
                // 神策标识用户
                SATracking.login(data.userId);
            }
            catch (err) {
                // 神策统计注册失败及原因
                SATracking.trackInterfaceResults({
                    desc: '申请注册结果',
                    describe: SATracking.serialize({
                        status_name: '失败',
                        msg: err.message,
                    }),
                });
                throw err;
            }
        },
        /**
         * 退出
         */
        async logout() {
            this.$reset();
            // 退出神策登录状态
            SATracking.logout();
        },
        /**
         * 发送登录短信验证码
         */
        async sendCaptcha({ phone }) {
            await userService.sendCaptcha({
                phone,
            });
        },
    },
    persist: {
        paths: ['token', 'identifier'],
    },
});
