import Vue from 'vue';
import VueRouter from 'vue-router';
// import StandardLayout from '@/layouts/standard-layout.vue';
import NotFound from '@/views/not-found.vue';
import { isPromise } from '@/shared/util';
import loginBackgroundImage from '@/assets/img/bg-login.png';
import cheasesRoutes from './routes/cheeses';
Vue.use(VueRouter);
/**
 * 每个路由的meta信息中可以设置以下参数
 * @param {String} title 当前页面的标题
 * @param {Boolean} excludeLogin 设置为true时表示当前路由对应的页面不需要登录，默认为false
 * @param {Boolean} excludeTracking 设置为true时表示当前路由跳过神策PV上报数据，默认为false
 * @param {String} background body背景色，默认为#f8f9ff
 */
const routes = [
    /**
     * 可以通过命名视图控制StandardLayout中的菜单功能
     * StandardLayout下的每个路由的meta信息可以设置以下几个参数
     * @param {Boolean} canBack 用于控制是否显示返回按钮，默认为true
     * @param {Boolean} customNavBar 用于控制是否自定义导航栏，当为true的时候StandardLayout默认的导航栏将被屏蔽，默认为false
     */
    /* {
      path: '/',
      component: StandardLayout,
      meta: {
        hasNavBarLayout: true,
      },
      children: [
      ],
    }, */
    /**
     * 默认将首页重定向到订单页
     */
    {
        path: '/',
        redirect: '/orders',
    },
    /**
     * 登录页
     */
    {
        path: '/login',
        meta: {
            title: '悦卡',
            excludeLogin: true,
            background: `#F5F6FA url(${loginBackgroundImage}) no-repeat 0 0 / 100% auto`,
        },
        component: () => import('@/views/login.vue'),
    },
    /**
     * 订单页
     */
    {
        path: '/orders',
        meta: {
            title: '权益订单',
            background: '#F8F9FF',
        },
        component: () => import('@/views/orders.vue'),
    },
    // 芝士
    ...cheasesRoutes,
    /**
     * 404
     */
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
];
export function createRouter() {
    const router = new VueRouter({
        mode: 'history',
        base: process.env.VUE_APP_PREFIX,
        routes,
        scrollBehavior() {
            return { y: 0, x: 0 };
        },
    });
    const originalPush = router.push;
    router.push = function push(...args) {
        const result = originalPush.apply(this, args);
        if (isPromise(result)) {
            return result.catch((err) => err);
        }
    };
    const originalReplace = router.replace;
    router.replace = function replace(...args) {
        const result = originalReplace.apply(this, args);
        if (isPromise(result)) {
            return result.catch((err) => err);
        }
    };
    return router;
}
