import BigNumber from 'bignumber.js';
/**
 * 金额分转元
 */
export default function cent2yuan(value) {
    let newValue = value;
    if (newValue == null || newValue === '') {
        newValue = 0;
    }
    return new BigNumber(newValue).div(100).toNumber();
}
