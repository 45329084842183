let PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhwCTHDE4zrA7JO5S2F02Rdc0MsLx68RfKK+m27H7dHWllojDk5tgF64E9R1OWYTyLBYLJGBSlI4/4fGOJNDCqrQT45WNMUEfV8rTkQd0C2oBraqM/90CCQ/6rYpvuc/OZq+y3v/Fj9Os5E8vf9r13nyDAePtsY/oO1LzZDmFMlOCTuA4wb9QuK+k35oGaMzlL8LXrV7hECvo5Q2SZ8Y1Hv5tVcAZ3W3h1OmMNP5nedLAPFIMpdvbLBmgEnxf7V3Ex0AjTkW+Gkc7vt4ymTLeEKUS1Z6SURtdjqxi16z+e3+9Ue7zLBxUdNVyPmXD4E/1MvpULh6WdTh/wgCqQxYlwQIDAQAB
-----END PUBLIC KEY-----
`;
if (process.env.VUE_APP_RUNTIME_ENV !== 'prod') {
    PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiowdil3cIVeyYBD9n1e7+w7ntC/33wmFnTmrI/ovu04CJkJ52iOj2JjXU5jr7gCEqSN8MAWws/BC2M/DNBgXJVlwCPRIoIuvm2x0+Y7QZ3ZeH8tANWbKsp3vDjEpawwp3KRpF5meJ3ZtgBJDoPJOZ+BvYb6NpXXNK1pqgHPOL8Rrak4XFySi6wcWg7m3LNGdUnw8E5nGu/gr7ZmiZkm48k9hc5wuN9o1MbDEuQnDzEO046++nuPZ9oqbB0QUEBKu7nO3pS7ZrPBrHodKnSgLx0/tvV8DiWJwcthnQp/xXuCsp+UtB9Ap6DnfdvwjDVdzuZvtkpR+2YDij81sNadbzQIDAQAB
-----END PUBLIC KEY-----
`;
}
export default PUBLIC_KEY;
