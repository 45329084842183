import { useUserStore } from '@/stores/modules/user';
import { useUTMStore } from '@/stores/modules/utm';
export default function loginGuard(router, pinia) {
    const userStore = useUserStore(pinia);
    const utmStore = useUTMStore(pinia);
    router.beforeEach((to, from, next) => {
        if (to.name === 'NotFound' || to.name === 'ChunkLoadError') {
            next();
            return;
        }
        // 携带了token的情况下不调用登录页面
        if (to.query.token != null) {
            userStore.update({
                token: to.query.token,
            });
            next();
            return;
        }
        if (userStore.isLogin || to.meta?.excludeLogin) {
            next();
            return;
        }
        next({
            path: '/login',
            query: {
                utm_source: utmStore.source,
                redirect: to.fullPath,
            },
            replace: true,
        });
    });
}
