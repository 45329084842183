/* vant-demand-import */
import installComponents from "!../../plugins/vant-demand-import/installComponents.js"
import VanCheckbox from 'vant/es/checkbox'
import 'vant/es/checkbox/style/less'
import '/Users/evan/work/benefits-mobile/vant-extra/style-override/checkbox.css'


import { render, staticRenderFns } from "./checkbox-with-tracking.vue?vue&type=template&id=d757589c&"
import script from "./checkbox-with-tracking.vue?vue&type=script&lang=ts&setup=true&"
export * from "./checkbox-with-tracking.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
installComponents(component, {VanCheckbox})
