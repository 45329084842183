import { get, isPlainObject, set } from 'lodash';
function makePersistState(state, paths) {
    if (Array.isArray(paths)) {
        return paths.reduce((acc, cur) => {
            set(acc, cur, get(state, cur));
            return acc;
        }, {});
    }
    return state;
}
export default function persist(context) {
    const { options, store } = context;
    if (!options.persist)
        return;
    const { storage = localStorage, serializer = {
        serialize: JSON.stringify,
        deserialize: JSON.parse,
    }, key = store.$id, paths, } = (isPlainObject(options.persist) ? options.persist : {});
    // 恢复之前持久化的状态
    try {
        const persistedState = storage.getItem(key);
        if (persistedState) {
            store.$patch(serializer.deserialize(persistedState));
        }
    }
    catch { }
    store.$subscribe((_, state) => {
        // 持久化state变更后的值
        try {
            const persistState = makePersistState(state, paths);
            storage.setItem(key, serializer.serialize(persistState));
        }
        catch { }
    }, { detached: true });
}
